import {CalculationDetailDto, DCFParameters, RentRollAssumptions} from "reia-rest-client";
import { getFormattedInt } from "./digitHelper";
import {
    getAccCostsTerminalValue,
    getDCFInput,
    getTotalSpace
} from "./dcfHelper";
import moment from "moment/moment";
import dayjs from "dayjs";
import {calculateAssetKpis} from "./kpiHelper";
import {getDefaultAccCostsValuesUI, getDurationInMonthsOrDefault} from "./dcfParamsHelper";
import {DCFOutput} from "reia-dcf-client";

export const getTotalDurationForPortfolio = (calculationDetails: CalculationDetailDto[]) => {
    const startAnalysisDates = calculationDetails.map(calcDetail => moment(calcDetail.analysisDate));
    const firstDate = moment.min(startAnalysisDates)
    const endAnalysisDates = calculationDetails.map(calcDetail => moment(calcDetail.analysisDate).add(getDurationInMonthsOrDefault(calcDetail.assetDCFParams), 'M'));
    const lastDate = moment.max(endAnalysisDates)
    const durationInMonths = Math.ceil(lastDate.diff(firstDate, 'months', true));

    return {
        firstDate: firstDate, lastDate: lastDate, durationInMonths: durationInMonths
    }

}

export const getFirstAndLastAnalysisDate = (calculationDetails: CalculationDetailDto[]) => {
    const analysisDates = calculationDetails.map(calcDetail => moment(calcDetail.analysisDate));
    const firstDate = moment.min(analysisDates)
    const lastDate = moment.max(analysisDates)

    return {
        firstDate: firstDate, lastDate: lastDate
    }
}

export const getMinDurationForPortfolio = (calculationDetails: CalculationDetailDto[]) => {
    return Math.min(...calculationDetails.map(calcDetail => calcDetail.assetDCFParams.durationInMonths))
}

export const getMaxDurationForPortfolio = (calculationDetails: CalculationDetailDto[]) => {
    return Math.max(...calculationDetails.map(calcDetail => calcDetail.assetDCFParams.durationInMonths))
}


export const getPerSpaceValuePortfolio = (value: number, calculationDetails: CalculationDetailDto[]) => {
    return getFormattedInt(value / getTotalSpacePortfolio(calculationDetails));
}

export const getTotalSpacePortfolio = (calculationDetails: CalculationDetailDto[]) => {
    return calculationDetails?.reduce((summedSpace, calculationDetail) =>
        summedSpace + getTotalSpace(calculationDetail.assetHVLParams, calculationDetail.rentRolls), 0);
}

export const getPortfolioAssetValue = (calculationDetails: CalculationDetailDto[], dcfResultsModified: {}, grossValue: boolean) => {
    return calculationDetails?.reduce((summedSpace, calculationDetail) => {
        let dcfResult = calculationDetail.assetDCFResult?.dcfResult;

        if (dcfResultsModified && dcfResultsModified.hasOwnProperty(calculationDetail.assetId)) {
            dcfResult = dcfResultsModified[calculationDetail.assetId]
        }

        if (dcfResult) {
            const assetValue = grossValue ? dcfResult.assetCashFlow.grossAssetValue : dcfResult.assetCashFlow.netAssetValue

            return summedSpace + assetValue
        } else {
            return summedSpace
        }

    }, 0);
}

export const getPortfolioAccCosts = (calculationDetails: CalculationDetailDto[], dcfResultsModified: {}, useTerminalValue: boolean, landTaxes : {}, acquisitionCostsDefaults : {}) => {
    return calculationDetails.reduce((summedSpace, calculationDetail: CalculationDetailDto) => {
        let dcfResult: DCFOutput = calculationDetail.assetDCFResult?.dcfResult;
        let currentGrossValue, currentNetValue, currentLandTransferTaxValue, currentAgentCostsValue, currentNotaryCostsValue, currentTotalAcquisitionCostsValue = 0;

        if (dcfResultsModified && dcfResultsModified.hasOwnProperty(calculationDetail.assetId)) {
            dcfResult = dcfResultsModified[calculationDetail.assetId]
        }
        
        if (dcfResult) {
            
            if (useTerminalValue) {
                //TODO: calc acc Costs for terminal value in portfolio ! missing federal states and default values 
                const accCosts = getDefaultAccCostsValuesUI(landTaxes, acquisitionCostsDefaults, calculationDetail.federalState ,dcfResult.assetCashFlow.netAssetValue, calculationDetail.assetDCFParams, true)
                
                const assetAccCostsTerminalValue = getAccCostsTerminalValue(calculationDetail.assetDCFParams, dcfResult, accCosts.landTransferTax, accCosts.agentCosts, accCosts.notaryCosts)

                currentGrossValue = assetAccCostsTerminalValue.grossValue
                currentNetValue = assetAccCostsTerminalValue.netValue
                currentLandTransferTaxValue = assetAccCostsTerminalValue.landTransferExitValue
                currentAgentCostsValue = assetAccCostsTerminalValue.agentCostsExitValue
                currentNotaryCostsValue = assetAccCostsTerminalValue.notaryCostsExitValue
                currentTotalAcquisitionCostsValue = assetAccCostsTerminalValue.totalAcquisitionCostsExitValue
            } else {
                
                currentGrossValue = dcfResult.assetCashFlow.grossAssetValue
                currentNetValue = dcfResult?.assetCashFlow?.netAssetValue
                currentLandTransferTaxValue = dcfResult.assetCashFlow.landTranferTax
                currentAgentCostsValue = dcfResult.assetCashFlow.agentCostsAsset
                currentNotaryCostsValue = dcfResult.assetCashFlow.notaryCosts
                currentTotalAcquisitionCostsValue = dcfResult.assetCashFlow.totalAcquisitionCosts
            }
        }

        return {
            grossValue: summedSpace.grossValue + currentGrossValue,
            netValue: summedSpace.netValue + currentNetValue,
            landTransferValue: summedSpace.landTransferValue + currentLandTransferTaxValue,
            notaryCostsValue: summedSpace.notaryCostsValue + currentNotaryCostsValue,
            agentCostsValue: summedSpace.agentCostsValue + currentAgentCostsValue,
            totalAcquisitionCostsValue: summedSpace.totalAcquisitionCostsValue + currentTotalAcquisitionCostsValue
        }

    }, { grossValue: 0, netValue: 0, landTransferValue: 0, notaryCostsValue: 0, agentCostsValue: 0, totalAcquisitionCostsValue: 0 });
}

export const comparePortfolioValue = (calculationDetails: CalculationDetailDto[], dcfResultsModified: {}) => {

    const origValue = getPortfolioAssetValue(calculationDetails);
    const newValue = getPortfolioAssetValue(calculationDetails, dcfResultsModified);

    return Math.round(origValue) === Math.round(newValue)
}

export const mergePortfolioCalculationDetails = (calculationDetails: CalculationDetailDto[], dcfResultsModified: {}, portfolioDCFParams: {}, assetTypesCapRatesDefaults: {}, landTaxes: {}, acquisitionCostsDefaults: {}): CalculationDetailDto[] => {

    return calculationDetails.map(calculationDetail => {

        const assetDCFParams = JSON.parse(JSON.stringify(calculationDetail.assetDCFParams));

        if (portfolioDCFParams.durationInMonths && portfolioDCFParams.durationInMonths > 0)
            assetDCFParams.durationInMonths = portfolioDCFParams.durationInMonths

        if (portfolioDCFParams.discountRate && portfolioDCFParams.discountRate > 0)
            assetDCFParams.discountRate = assetDCFParams.discountRate + portfolioDCFParams.discountRate

        if (portfolioDCFParams.exitCapRate && portfolioDCFParams.exitCapRate > 0)
            assetDCFParams.exitCapRate = assetDCFParams.exitCapRate + portfolioDCFParams.exitCapRate

        const mergedCalculationDetailDto: CalculationDetailDto = {
            ...calculationDetail,
            assetDCFParams: assetDCFParams
        }

        if (dcfResultsModified.hasOwnProperty(calculationDetail.assetId)) {
            mergedCalculationDetailDto.assetDCFResult = {
                dcfResult: dcfResultsModified[calculationDetail.assetId]
            }
        }
        mergedCalculationDetailDto.assetKPIs = calculateAssetKpis(mergedCalculationDetailDto, mergedCalculationDetailDto.assetDCFResult.dcfResult, mergedCalculationDetailDto.assetDCFParams, assetTypesCapRatesDefaults, landTaxes, acquisitionCostsDefaults)
        mergedCalculationDetailDto.rentRolls= null
        mergedCalculationDetailDto.rentRollsAssumptions= null
        
        return mergedCalculationDetailDto;
    });
}

export const getPortfolioDCFInputs = (calculationDetails: CalculationDetailDto[], rentRollAssumptions: RentRollAssumptions, portfolioDCFParams: {}, landTaxes: {}, acquisitionCostsDefaults: {}, assetTypesCapRatesDefaults: {}, useTypesCostsDefaults: {}) => {
    return calculationDetails.map(calculationDetail => {

        const assetDCFParams = JSON.parse(JSON.stringify(calculationDetail.assetDCFParams));
        const analysisDate = portfolioDCFParams.analysisDate ? portfolioDCFParams.analysisDate : calculationDetail.analysisDate

        if (portfolioDCFParams.durationInMonths && portfolioDCFParams.durationInMonths > 0)
            assetDCFParams.durationInMonths = portfolioDCFParams.durationInMonths

        if (portfolioDCFParams.discountRate && portfolioDCFParams.discountRate > 0)
            assetDCFParams.discountRate = assetDCFParams.discountRate + portfolioDCFParams.discountRate

        if (portfolioDCFParams.exitCapRate && portfolioDCFParams.exitCapRate > 0)
            assetDCFParams.exitCapRate = assetDCFParams.exitCapRate + portfolioDCFParams.exitCapRate

        return getDCFInput(calculationDetail.assetId, analysisDate, assetDCFParams, calculationDetail.assetHVLParams, Object.values(calculationDetail.rentRolls), rentRollAssumptions, calculationDetail.indexValuesAnalyseDate, calculationDetail?.assetDCFResult?.dcfResult, landTaxes, acquisitionCostsDefaults, calculationDetail.federalState, assetTypesCapRatesDefaults, useTypesCostsDefaults, calculationDetail.assetType);
    });
}

export const getPortfolioSumProduct = (calculationDetails: CalculationDetailDto[], dcfResultsModified: {}, targetParam: string, relativeParam: string, portfolioDCFParams: {}) => {

    let relativeSum = 0;
    const sumProduct = calculationDetails.reduce((summedSpace, calculationDetail) => {
        let dcfResult = calculationDetail.assetDCFResult?.dcfResult;

        if (dcfResultsModified && dcfResultsModified.hasOwnProperty(calculationDetail.assetId)) {
            dcfResult = dcfResultsModified[calculationDetail.assetId]
        }

        if (dcfResult) {
            let targetParamValue = calculationDetail.assetDCFParams[targetParam] / 100;

            if (portfolioDCFParams[targetParam] && portfolioDCFParams[targetParam] > 0)
                targetParamValue = targetParamValue + portfolioDCFParams[targetParam] / 100

            let relativeParamValue = 0;
            switch (relativeParam) {
                case "capitalNetValue":
                    relativeParamValue = dcfResult.assetCashFlow.netAssetValue;
                    if (relativeParamValue < 0)
                        relativeParamValue = 0;

                    relativeSum = relativeSum + relativeParamValue;
                    break;
            }
            return summedSpace + (targetParamValue * relativeParamValue);
        } else {
            return summedSpace;
        }

    }, 0);

    return sumProduct / relativeSum
}

const addArrayNumbers = (data) => {
    return data.reduce((accumulator, current) => accumulator + current, 0)
}

export const netOperatingIncomeInMonth = (calculationDetails, projectionPeriod) => {
    if (projectionPeriod) {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalNetOperatingIncome?.at(-1)))
    } else {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalNetOperatingIncome[0]))
    }
}

export const grossOperatingIncomeInMonth = (calculationDetails, projectionPeriod) => {
    if (projectionPeriod) {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalGrossRentalIncome?.at(-1)))
    } else {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalGrossRentalIncome[0]))
    }
}

export const potentialRentInMonth = (calculationDetails, projectionPeriod) => {
    if (projectionPeriod) {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalPotentialRent?.at(-1)))
    } else {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalPotentialRent[0]))
    }
}

export const marketRentInMonth = (calculationDetails, projectionPeriod) => {
    if (projectionPeriod) {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalMArketRent?.at(-1)))
    } else {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalMArketRent[0]))
    }
}
export const nonRecoverableCostInMonth = (calculationDetails, projectionPeriod) => {
    if (projectionPeriod) {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalNonRecs?.at(-1)))
    } else {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalNonRecs[0]))
    }
}
export const otherIncomCostBeforeNOIInMonth = (calculationDetails, projectionPeriod) => {
    if (projectionPeriod) {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalNonRecs?.at(-1)))
    } else {
        return addArrayNumbers(calculationDetails?.calculationDetailDtos.map(item => item.assetDCFResult?.dcfResult?.assetCashFlow?.totalNonRecs[0]))
    }
}

//*****************Finding Total lettable area
export const getPortfolioTotalLettableArea = (calculationDetailsByPortfolio) => {
    const totalRentRolls = calculationDetailsByPortfolio?.calculationDetailDtos?.map(item => item.rentRolls);
    const rentRollsObject = totalRentRolls.map(item => Object.values(item));
    const flattenedRentRollsArray = rentRollsObject.flat();
    const rentRollsLettableArea = flattenedRentRollsArray.reduce((acc, obj) => {
        return acc + (obj.rentalSpace || 0);
    }, 0);
    const totalLettableArea = rentRollsLettableArea ??
        calculationDetailsByPortfolio.calculationDetailDtos?.map(item => item?.assetHVLParams?.totalLettableArea).reduce((acc, current) => acc + current, 0);
    return totalLettableArea
}



//*****************Finding Portfolio Acc values
export const getPortfolioTerminalValues = (calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults) => {
    const { grossValue, netValue, landTransferValue, agentCostsValue, notaryCostsValue, totalAcquisitionCostsValue } = getPortfolioAccCosts(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults)
    const grossCapitalValue = getPerSpaceValuePortfolio(grossValue, calculationDetailsByPortfolio?.calculationDetailDtos)
    const landTransferTax = landTransferValue / netValue * 100;
    const agentCosts_Sale = agentCostsValue / netValue * 100;
    const notaryCosts = notaryCostsValue / netValue * 100;
    return {
        grossValue, netValue, grossCapitalValue, landTransferValue, landTransferTax, agentCosts_Sale, agentCostsValue, notaryCosts, notaryCostsValue, totalAcquisitionCostsValue
    }
}

//*****************Finding Yield profile 
export const getPortfolioYieldProfileCalculations = (calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults) => {
    const totalNetOperatingIncomeInMonth = netOperatingIncomeInMonth(calculationDetailsByPortfolio)
    const totalGrossOperatingIncomeInMonth = grossOperatingIncomeInMonth(calculationDetailsByPortfolio)
    const totalPotentialRentInMonth = potentialRentInMonth(calculationDetailsByPortfolio)
    const totalMarketRentInMonth = marketRentInMonth(calculationDetailsByPortfolio)
    const totalNonRecoverableCostInMonth = nonRecoverableCostInMonth(calculationDetailsByPortfolio)
    const totalOotherIncomCostBeforeNOIInMonth = otherIncomCostBeforeNOIInMonth(calculationDetailsByPortfolio)
    const accValues = getPortfolioTerminalValues(calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults);

    const yielCurrentNIY = (totalNetOperatingIncomeInMonth * 12) / accValues?.grossValue;
    const yielCurrentGIY = (totalGrossOperatingIncomeInMonth * 12) / accValues?.netValue;
    const yielPotentialNIY = ((totalPotentialRentInMonth - (totalNonRecoverableCostInMonth + totalOotherIncomCostBeforeNOIInMonth)) * 12) / accValues?.grossValue;
    const yielPotentialGIY = (totalPotentialRentInMonth * 12) / accValues?.netValue;
    const yielMarketNIY = ((totalMarketRentInMonth - (totalNonRecoverableCostInMonth + totalOotherIncomCostBeforeNOIInMonth)) * 12) / accValues?.grossValue;
    const yielMarketGIY = (totalMarketRentInMonth * 12) / accValues?.netValue;
    return { yielCurrentNIY, yielCurrentGIY, yielPotentialNIY, yielPotentialGIY, yielMarketNIY, yielMarketGIY }
}
//*****************Finding Yield profile 
export const getPortfolioYieldProfileAtSaleCalculations = (calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults) => {
    const totalNetOperatingIncomeInLastMonth = netOperatingIncomeInMonth(calculationDetailsByPortfolio, true)
    const totalGrossOperatingIncomeInLastMonth = grossOperatingIncomeInMonth(calculationDetailsByPortfolio, true)
    const totalPotentialRentInLastMonth = potentialRentInMonth(calculationDetailsByPortfolio, true)
    const totalMarketRentInLastMonth = marketRentInMonth(calculationDetailsByPortfolio, true)
    const totalNonRecoverableCostInLastMonth = nonRecoverableCostInMonth(calculationDetailsByPortfolio, true)
    const totalOotherIncomCostBeforeNOIInLastMonth = otherIncomCostBeforeNOIInMonth(calculationDetailsByPortfolio, true)
    const accValues = getPortfolioTerminalValues(calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults);

    const yielSaleCurrentNIY = (totalNetOperatingIncomeInLastMonth * 12) / accValues?.grossValue;
    const yielSaleCurrentGIY = (totalGrossOperatingIncomeInLastMonth * 12) / accValues?.netValue;
    const yielSalePotentialNIY = ((totalPotentialRentInLastMonth - (totalNonRecoverableCostInLastMonth + totalOotherIncomCostBeforeNOIInLastMonth)) * 12) / accValues?.grossValue;
    const yielSalePotentialGIY = (totalPotentialRentInLastMonth * 12) / accValues?.netValue;
    const yielSaleMarketNIY = ((totalMarketRentInLastMonth - (totalNonRecoverableCostInLastMonth + totalOotherIncomCostBeforeNOIInLastMonth)) * 12) / accValues?.grossValue;
    const yielSaleMarketGIY = (totalMarketRentInLastMonth * 12) / accValues?.netValue;
    return { yielSaleCurrentNIY, yielSaleCurrentGIY, yielSalePotentialNIY, yielSalePotentialGIY, yielSaleMarketNIY, yielSaleMarketGIY }
}

//*****************Finding Vacancy Rate
export const getPortfolioVacancyRate = (calculationDetailsByPortfolio) => {
    const totalLettableArea = getPortfolioTotalLettableArea(calculationDetailsByPortfolio);
    const totalRentRolls = calculationDetailsByPortfolio?.calculationDetailDtos?.map(item => item.rentRolls)
    const rentRollsObject = totalRentRolls.map(item => Object.values(item));
    const flattenedRentRollsArray = rentRollsObject.flat();
    const vacantRentalSpace =
        rentRollsObject &&
        flattenedRentRollsArray
            ?.filter(
                (list) =>
                    list?.rentRollStatusType?.key ===
                    "core-data.rentRollStatusTypes.vacant"
            )
            ?.reduce((total, item) => total + item.rentalSpace, 0);
    const vacancyRate = vacantRentalSpace / totalLettableArea;
    return vacancyRate;

}

//*****************Finding WALT Years
export const getPortfolioWALTYears = (calculationDetailsByPortfolio) => {
    const totalRentRolls = calculationDetailsByPortfolio?.calculationDetailDtos?.map(item => item.rentRolls)
    const rentRollsObject = totalRentRolls.map(item => Object.values(item));
    const flattenedRentRollsArray = rentRollsObject.flat();
    const rentRolls = flattenedRentRollsArray;
    const totalGrossRentalIncome = calculationDetailsByPortfolio?.calculationDetailDtos.map(item => item?.assetDCFResult?.dcfResult?.assetCashFlow?.totalGrossRentalIncome);
    const grossRentalIncomeTenant = calculationDetailsByPortfolio?.calculationDetailDtos.map(item => item?.assetDCFResult?.dcfResult?.tenantCashFlows[0]?.grossRentalIncome)
    const leaseDuration = calculationDetailsByPortfolio?.calculationDetailDtos.map(item => item?.assetHVLParams?.leaseDuration).reduce((acc, current) => acc + current, 0)
    let waltRationPerRentalUnit;

    if (dayjs(rentRolls[0]?.leaseEndDate).diff(
        dayjs(calculationDetailsByPortfolio?.analysisDate),
        "days"
    ) > 0
    ) {
        const divisionResults = grossRentalIncomeTenant
            ?.map(
                (value, index) => (value / totalGrossRentalIncome[index]) * 2 // will replace with leaseEnd Date
            )
            ?.filter((num) => !isNaN(num));
        waltRationPerRentalUnit = divisionResults.reduce(
            (total, item) => total + item,
            0
        );
    } else {
        const divisionResults = grossRentalIncomeTenant
            ?.map(
                (value, index) => (value / totalGrossRentalIncome[index]) * 2 // will replace with analysis Date
            )
            ?.filter((num) => !isNaN(num));
        waltRationPerRentalUnit = divisionResults?.reduce(
            (total, item) => total + item,
            0
        );
    }
    const waltYears =
        (waltRationPerRentalUnit - leaseDuration) /
        36525;
    return waltYears
}