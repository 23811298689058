import { Table } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DCFOutput, TenantCashFlow } from "reia-dcf-client";
import { AssetDto, RentRollDto } from "reia-rest-client";
import { v4 as uuidv4 } from 'uuid';
import type { Cashflow } from "../../../../../../utils/cashflowHelper";
import { getFormattedEnglishNumber } from "../../../../../../utils/digitHelper";
import {getDurationInMonthsOrDefault} from "../../../../../../utils/dcfParamsHelper";

export default function CashflowDataTable(props) {
    const { showTanents, active } = props;
    const assetDCFParams = useSelector((state) => state.calculation.assetDCFParams);
    const { durationInMonths, triggerFinancingCosts } = assetDCFParams;
    const { asset }: { asset: AssetDto } = useSelector((state) => state.assets);
    const { assetDCFResult, isLoading, calculationDetail, assetCashflows } = useSelector((state) => state.calculation);
    const allRentRollsByAsset = calculationDetail ? calculationDetail.rentRolls : null
    const [cashflowArrays, setCashflowArrays] = useState({ dataSourceMonth: [], dataSourceYear: [] });
    const [cashflowArraysWithTenants, setCashflowArraysWithTenants] = useState({
        dataSourceMonth: [],
        dataSourceYear: []
    });
    const [tableColumns, setTableColmuns] = useState({ monthColumns: [], yearColumns: [] });
    const dcfResult: DCFOutput = assetDCFResult?.dcfResult;
    const tenantTitle = {
        title: <div style={{ fontWeight: 500 }}>Tenant Cash Flow</div>,
    }
    const assetTitle = {
        title: <div style={{ fontWeight: 500 }}>Asset Cash Flow</div>,
    }
    
    const getDurationInMonths = () => {
        return getDurationInMonthsOrDefault(assetDCFParams)
    }
    
    const getCashflowsArray = (startDate: moment.Moment, columnsTitle: string, monthlyDataArray: {}) => {
        const dataCashFlow = {};
        const dataCashFlowYear = {};

        // Calculate per month according to the year
        //let leftYearMonths = 12 - startDate.month()
        // Calculation if we just add 12 months, ignoring the current calendaryear
        let leftYearMonths = 12
        let yearAmount = 0;
        let yearIndex = 0;
        let month;
        dataCashFlow.title = columnsTitle;
        dataCashFlowYear.title = columnsTitle;
        for (month = 0; month < monthlyDataArray.data.length; month++) {
            const nextMonthAmount = parseInt(monthlyDataArray.data[month])
            dataCashFlow[month] = nextMonthAmount;
            yearAmount = yearAmount + nextMonthAmount;
            leftYearMonths--;
            if (leftYearMonths < 1) {
                dataCashFlowYear[yearIndex] = yearAmount;
                yearAmount = 0;
                let openMonths = getDurationInMonths() - month - 1;
                leftYearMonths = openMonths >= 12 ? 12 : openMonths;
                yearIndex++
            }
        }

        if (monthlyDataArray.children) {
            dataCashFlow.children = [];
            dataCashFlowYear.children = [];
            monthlyDataArray.children.forEach(childItem => {
                const {
                    monthlyCashflow,
                    yearlyCashFlow
                } = getCashflowsArray(startDate, childItem.title, childItem.data);
                dataCashFlow.children.push(monthlyCashflow);
                dataCashFlowYear.children.push(yearlyCashFlow);
            })
        }

        return { monthlyCashflow: dataCashFlow, yearlyCashFlow: dataCashFlowYear }
    }

    const rentRollsByAsset = calculationDetail ? calculationDetail?.rentRolls ? Object.values(calculationDetail?.rentRolls) : [] : null;

    useEffect(() => {
        // console.log("Start calc!")
        const dataSourceMonth = [];
        const dataSourceYear = [];
        const dataSourceMonthWithTenants = [];
        const dataSourceYearWithTenants = [];
        dataSourceMonthWithTenants.push(tenantTitle)
        dataSourceYearWithTenants.push(tenantTitle)

        const yearsColumn = [{
            title: "Yearly Cash Flow",
            dataIndex: "title",
            fixed: "left",
            width: "280px",
            align: "left",
            render: (text) => <div className="text-blue">{text}</div>,
        }];
        const monthColumns = [{
            title: "Monthly Cash Flow",
            dataIndex: "title",
            fixed: "left",
            width: "280px",
            align: "left",
            render: (text) => <div className="text-blue">{text}</div>,
        }];

        setTableColmuns({ monthColumns: monthColumns, yearColumns: yearsColumn })

        let month;
        for (month = 0; month < getDurationInMonths(); month++) {
            var futureMonth = moment(asset?.analysisDate).add(month, 'M');
            monthColumns.push({
                title: moment(futureMonth).format("MM/YYYY"),
                dataIndex: month,
                width: 110,
                align: 'right',
                render: text => {
                    return text !== undefined ? <div style={{ color: text < 0 ? '#FC0606' : 'auto' }}>{getFormattedEnglishNumber(text) + " €"}</div> : ""
                }
            })
        }

        const firstYear = moment(asset?.analysisDate).year();
        //Last year if we just add 12 months, ignoring the current calendaryear
        const lastYear = firstYear + getDurationInMonths() / 12 - 1;
        let year = firstYear;
        let yearDataIndex = 0;
        const startMonth = moment(asset?.analysisDate).month();
        const mixedYear = startMonth > 0;
        for (year = firstYear; year <= lastYear; year++) {
            yearsColumn.push({
                title: mixedYear ? year + "/" + (year + 1) : year,
                dataIndex: yearDataIndex,
                width: 110,
                align: 'right',
                render: text => text !== undefined ? <div style={{ color: text < 0 ? '#FC0606' : 'auto' }}>{getFormattedEnglishNumber(text) + " €"}</div> : ""
            });
            yearDataIndex++;
        }

        if (dcfResult?.tenantCashFlows) {
            Object.values(dcfResult?.tenantCashFlows).forEach((tenantCashFlow: TenantCashFlow) => {
                let columnTitle = tenantCashFlow.rentRollId;
                if (rentRollsByAsset && rentRollsByAsset.length > 0) {
                    const tenantName = rentRollsByAsset?.find((rentRoll: RentRollDto) => rentRoll?.id === parseInt(tenantCashFlow.rentRollId))?.tenantName
                    columnTitle = tenantName ? `${tenantCashFlow.rentRollId} - ${tenantName}` : columnTitle;
                }
                const {
                    monthlyCashflow,
                    yearlyCashFlow
                } = getCashflowsArray(moment(asset?.analysisDate), columnTitle, { data: tenantCashFlow["grossRentalIncome"] })
                dataSourceYearWithTenants.push(yearlyCashFlow);
                dataSourceMonthWithTenants.push(monthlyCashflow);
            });

            dataSourceMonthWithTenants.push(assetTitle)
            dataSourceYearWithTenants.push(assetTitle)

            const cashflowTitles = {
                totalGrossRentalIncome: 'Gross Rental Income - GRI',
                totalMArketRent: "Market Rent",
                totalPotentialRent: "Potential Rent",
                totalNonRecs: 'Non Recoverable Costs',
                totalMaintenanceCosts: 'Maintenance Costs',
                totalManagementCosts: 'Management Costs',
                totalOtherCosts: 'Other Costs',
                otherIncomeCosts_beforeNOI: 'Other Income/Costs Before NOI',
                totalNetOperatingIncome: "Net Operating Income - NOI",
                totalRelettingCosts: "Reletting & Vacancy Costs",
                totalTenantImprovementCosts: 'Tenant Improvement Costs',
                totalAgentCosts: "Agent Costs",
                totalVacancyCosts: "Vacancy Costs",
                otherIncomeCosts_afterNOI: 'Other Income/Costs After NOI',
                totalFreeCashflow: "Free Cash Flow - FCF",
                discountedCashFlowArray: "Discounted Cash Flow - DCF",
                runningCashOnCash: 'Running Cash on Cash',
                financingCF: 'Financing CF',
                leveragedCashFlow: 'Leveraged Cash Flow',
                finalInstalment: 'Final Instalment',
                prepaymentFee: 'Prepayment Fee',
                amortization: 'Amortization',
                totalIntest: 'Total Intest',
                loan: 'Loan',
                'Total Financing Cash Flow': 'Total Financing Cash Flow'

            }
            const changeTitle = (item) => {
                if (item.children) {
                    item.children.forEach(childItem => {
                        changeTitle(childItem);
                    });
                }
                item.title = cashflowTitles[item.title];
                item.key = uuidv4();
            };

            if (dcfResult?.assetCashFlow) {
                Object.keys(dcfResult?.assetCashFlow).forEach(dcfItem => {
                    switch (dcfItem) {
                        case "totalGrossRentalIncome":
                        // case "totalMArketRent":
                        // case "totalPotentialRent":
                        case "totalNonRecs":
                        case "otherIncomeCosts_beforeNOI":
                        case "totalNetOperatingIncome":
                        // case "totalTenantImprovementCosts":
                        // case "totalAgentCosts":
                        // case "totalVacancyCosts":
                        case "totalRelettingCosts":
                        case "otherIncomeCosts_afterNOI":
                        case "totalFreeCashflow":
                        case "discountedCashFlowArray":

                            if (dcfResult.assetCashFlow[dcfItem]) {
                                const dataItem = { data: dcfResult.assetCashFlow[dcfItem] };
                                if (dcfItem === "totalGrossRentalIncome") {
                                    dataItem.children = [];
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalMArketRent"] },
                                        title: "totalMArketRent"
                                    });
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalPotentialRent"] },
                                        title: "totalPotentialRent"
                                    });
                                }
                                if (dcfItem === "totalNonRecs") {
                                    dataItem.children = [];
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalMaintenanceCosts"] },
                                        title: "totalMaintenanceCosts"
                                    });
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalManagementCosts"] },
                                        title: "totalManagementCosts"
                                    });
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalOtherCosts"] },
                                        title: "totalOtherCosts"
                                    });
                                }
                                if (dcfItem === "totalRelettingCosts") {
                                    dataItem.children = [];
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalTenantImprovementCosts"] },
                                        title: "totalTenantImprovementCosts"
                                    });
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalAgentCosts"] },
                                        title: "totalAgentCosts"
                                    });
                                    dataItem.children.push({
                                        data: { data: dcfResult.assetCashFlow["totalVacancyCosts"] },
                                        title: "totalVacancyCosts"
                                    });
                                }

                                const {
                                    monthlyCashflow,
                                    yearlyCashFlow
                                } = getCashflowsArray(moment(asset?.analysisDate), dcfItem, dataItem)
                                changeTitle(monthlyCashflow)
                                changeTitle(yearlyCashFlow)
                                dataSourceYear.push(yearlyCashFlow);
                                dataSourceMonth.push(monthlyCashflow);
                                dataSourceYearWithTenants.push(yearlyCashFlow);
                                dataSourceMonthWithTenants.push(monthlyCashflow);
                                break;
                            }
                    }
                })

                // if (triggerFinancingCosts && assetCashflows.financingCashflows) {
                //     const financingHeading = "Total Financing Cash Flow"
                //     const dataItemMonthChildren = [];
                //     const dataItemYearChildren = [];

                //     Object.values(assetCashflows.financingCashflows).forEach((cashflow: Cashflow) => {
                //         dataItemMonthChildren.push({ title: cashflow.cashflowName, ...cashflow.cashflowDataMonthly })
                //         dataItemYearChildren.push({ title: cashflow.cashflowName, ...cashflow.cashflowDataYearly })
                //     })

                //     dataSourceMonth.push({ title: financingHeading, children: dataItemMonthChildren })
                //     dataSourceYear.push({ title: financingHeading, children: dataItemYearChildren })
                // }

                // dataSourceMonthWithTenants.push(...dataSourceMonth)
                // dataSourceYearWithTenants.push(...dataSourceYear)

                setCashflowArrays({ dataSourceMonth: dataSourceMonth, dataSourceYear: dataSourceYear });
                setCashflowArraysWithTenants({
                    dataSourceMonth: dataSourceMonthWithTenants,
                    dataSourceYear: dataSourceYearWithTenants
                });
            }

            if (triggerFinancingCosts && dcfResult?.financingCashFlow) {
                const dcfItem = "Total Financing Cash Flow"
                const dataItem = { data: [], children: [] };
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["loan"] },
                    title: "loan"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["totalIntest"] },
                    title: "totalIntest"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["amortization"] },
                    title: "amortization"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["prepaymentFee"] },
                    title: "prepaymentFee"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["finalInstalment"] },
                    title: "finalInstalment"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["leveragedCashFlow"] },
                    title: "leveragedCashFlow"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["financingCF"] },
                    title: "financingCF"
                });
                dataItem.children.push({
                    data: { data: dcfResult.financingCashFlow["runningCashOnCash"] },
                    title: "runningCashOnCash"
                });

                const {
                    monthlyCashflow,
                    yearlyCashFlow
                } = getCashflowsArray(moment(asset?.analysisDate), dcfItem, dataItem)
                changeTitle(monthlyCashflow)
                changeTitle(yearlyCashFlow)
                dataSourceYear.push(yearlyCashFlow);
                dataSourceMonth.push(monthlyCashflow);
                dataSourceYearWithTenants.push(yearlyCashFlow);
                dataSourceMonthWithTenants.push(monthlyCashflow);
            }

            const markForRemovalIfSumZero = (data, targetTitle) => {
                // Filter the items based on the target title
                const filteredItems = data.filter(item => item.title === targetTitle);
                // Check if all numeric values in the filtered items are zero
                const allValuesZero = filteredItems.every(currentItem => {
                    let sum = 0;
                    let hasNumericValue = false;
                    for (let key in currentItem) {
                        if (typeof currentItem[key] === 'number') {
                            hasNumericValue = true;
                            sum += currentItem[key];
                        }
                    }
                    return hasNumericValue && sum === 0;
                });

                // If all numeric values are zero, mark the items for removal
                if (allValuesZero) {
                    return data.map(item => item.title === targetTitle ? { ...item, isRemoved: true } : item);
                }
                return data;
            };

            const changeTitleOtherCost = (data) => {
                let result = [...data];
                // Get unique titles from the data
                const uniqueTitles = [...new Set(data.map(item => item.title))];
                // Iterate over each unique title and mark items for removal if their sum is zero
                uniqueTitles.forEach(title => {
                    result = markForRemovalIfSumZero(result, title);
                });

                return result.filter(item => !item?.isRemoved);
            };


            setCashflowArrays({ dataSourceMonth: changeTitleOtherCost(dataSourceMonth), dataSourceYear: changeTitleOtherCost(dataSourceYear) });
            setCashflowArraysWithTenants({
                dataSourceMonth: changeTitleOtherCost(dataSourceMonthWithTenants),
                dataSourceYear: changeTitleOtherCost(dataSourceYearWithTenants)
            });

        }
    }, [asset, durationInMonths, dcfResult, allRentRollsByAsset]);

    const highlighted = [
        "Net Operating Income - NOI",
        "Free Cash Flow - FCF",
        // "Discounted Cash Flow - DCF",
        "Total Financing Cash Flow",
        "Leverage Cash Flow",
    ];

    console.log(cashflowArrays, 'cashflowArrays')
    console.log(showTanents ? cashflowArraysWithTenants.dataSourceMonth : cashflowArrays.dataSourceMonth, ' showTanents ? cashflowArraysWithTenants.dataSourceMonth : cashflowArrays.dataSourceMonth')
    return (
        <Fragment>
            <Table
                rowClassName={(record) =>
                    highlighted.includes(record?.title)
                        ? "highlighted-row table-row-light"
                        : "table-row-light"
                }
                className="antd-custom-card-table cashflow-table py-3"
                columns={active === "monthly" ? tableColumns.monthColumns : tableColumns.yearColumns}
                dataSource={active === "monthly" ? showTanents ? cashflowArraysWithTenants.dataSourceMonth : cashflowArrays.dataSourceMonth : showTanents ? cashflowArraysWithTenants.dataSourceYear : cashflowArrays.dataSourceYear}
                loading={isLoading}
                pagination={false}
                scroll={{
                    x: 1300,
                }}
            />
        </Fragment >
    );
}

CashflowDataTable.propTypes = {
    showTanents: PropTypes.node.isRequired,
    active: PropTypes.node.isRequired,
    cashFlowMonthly: PropTypes.node.isRequired,
    isLoading: PropTypes.node.isRequired,
};
